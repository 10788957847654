export const MoCCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 54.03,
    prev_app_rate: 52.74,
    current_avg_round: 7.7,
    prev_avg_round: 8.09
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.79,
    prev_app_rate: 2.79,
    current_avg_round: 9.14,
    prev_avg_round: 8.82
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.03,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.62,
    prev_app_rate: 2.56,
    current_avg_round: 9.5,
    prev_avg_round: 8.73
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 39.71,
    prev_app_rate: 40.49,
    current_avg_round: 7.69,
    prev_avg_round: 7.64
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 1.49,
    prev_app_rate: 1.75,
    current_avg_round: 8.92,
    prev_avg_round: 8.55
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 21.83,
    prev_app_rate: 18.63,
    current_avg_round: 8.24,
    prev_avg_round: 8.39
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 5.41,
    prev_app_rate: 1.22,
    current_avg_round: 8.14,
    prev_avg_round: 8.27
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 10.07,
    prev_app_rate: 8.63,
    current_avg_round: 7.7,
    prev_avg_round: 8.56
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 16.51,
    prev_app_rate: 14.14,
    current_avg_round: 7.97,
    prev_avg_round: 7.88
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 7.69,
    prev_app_rate: 17.7,
    current_avg_round: 8.16,
    prev_avg_round: 7.81
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.11,
    prev_app_rate: 0.03,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 16.93,
    prev_app_rate: 14.06,
    current_avg_round: 8.12,
    prev_avg_round: 7
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.06,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 57.07,
    prev_app_rate: 76.4,
    current_avg_round: 7.79,
    prev_avg_round: 7.84
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 40.7,
    prev_app_rate: 37.91,
    current_avg_round: 7.75,
    prev_avg_round: 8.04
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 60.24,
    prev_app_rate: 79.14,
    current_avg_round: 7.71,
    prev_avg_round: 7.86
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 2.51,
    prev_app_rate: 2.4,
    current_avg_round: 8.08,
    prev_avg_round: 8.9
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 0.81,
    prev_app_rate: 6,
    current_avg_round: 8.61,
    prev_avg_round: 8.85
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.83,
    prev_app_rate: 0.91,
    current_avg_round: 8.43,
    prev_avg_round: 9.07
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 0.29,
    prev_app_rate: 0.81,
    current_avg_round: 99.99,
    prev_avg_round: 8
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 0.3,
    prev_app_rate: 11.03,
    current_avg_round: 99.99,
    prev_avg_round: 8.22
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.06,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 30.49,
    prev_app_rate: 18.76,
    current_avg_round: 8.09,
    prev_avg_round: 8.14
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 63.35,
    prev_app_rate: 85.9,
    current_avg_round: 7.85,
    prev_avg_round: 7.94
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 12.23,
    prev_app_rate: 2.94,
    current_avg_round: 8.35,
    prev_avg_round: 8.67
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 2.46,
    prev_app_rate: 3.65,
    current_avg_round: 8.28,
    prev_avg_round: 7.39
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 28.09,
    prev_app_rate: 28.09,
    current_avg_round: 7.18,
    prev_avg_round: 7.18
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 0.88,
    prev_app_rate: 2.43,
    current_avg_round: 7.95,
    prev_avg_round: 9.13
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 6.94,
    prev_app_rate: 6.26,
    current_avg_round: 9.21,
    prev_avg_round: 7.6
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 17.64,
    prev_app_rate: 18.98,
    current_avg_round: 8.65,
    prev_avg_round: 8.6
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.46,
    prev_app_rate: 0.54,
    current_avg_round: 8.67,
    prev_avg_round: 99.99
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 16.46,
    prev_app_rate: 13.95,
    current_avg_round: 8.42,
    prev_avg_round: 8.27
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 2.37,
    prev_app_rate: 1.47,
    current_avg_round: 8.12,
    prev_avg_round: 8.06
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.28,
    prev_app_rate: 0.31,
    current_avg_round: 9.44,
    prev_avg_round: 99.99
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    rarity: '4*',
    current_app_rate: 9.69,
    prev_app_rate: 9.69,
    current_avg_round: 8.52,
    prev_avg_round: 8.52
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.27,
    prev_app_rate: 0.04,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.13,
    prev_app_rate: 0.19,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 33.44,
    prev_app_rate: 33.67,
    current_avg_round: 7.72,
    prev_avg_round: 7.98
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.59,
    prev_app_rate: 0.84,
    current_avg_round: 9.3,
    prev_avg_round: 7.08
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 33.94,
    prev_app_rate: 29.69,
    current_avg_round: 7.42,
    prev_avg_round: 7.25
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 83.88,
    prev_app_rate: 94.03,
    current_avg_round: 7.89,
    prev_avg_round: 7.97
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 1.06,
    prev_app_rate: 0.15,
    current_avg_round: 8.52,
    prev_avg_round: 99.99
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 1.17,
    prev_app_rate: 1.74,
    current_avg_round: 8.21,
    prev_avg_round: 8.25
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.12,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 19.47,
    prev_app_rate: 28.14,
    current_avg_round: 7.96,
    prev_avg_round: 7.83
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 40.56,
    prev_app_rate: 31.72,
    current_avg_round: 7.77,
    prev_avg_round: 8.1
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.17,
    prev_app_rate: 0.46,
    current_avg_round: 99.99,
    prev_avg_round: 8.86
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 21.81,
    prev_app_rate: 11.07,
    current_avg_round: 7.5,
    prev_avg_round: 8.27
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 13.76,
    prev_app_rate: 21.97,
    current_avg_round: 7.52,
    prev_avg_round: 7.28
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.79,
    prev_app_rate: 0.99,
    current_avg_round: 9.14,
    prev_avg_round: 9.5
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.16,
    prev_app_rate: 0.26,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.56,
    prev_app_rate: 0.06,
    current_avg_round: 8.07,
    prev_avg_round: 99.99
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.33,
    prev_app_rate: 0.01,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 18.36,
    prev_app_rate: 18.36,
    current_avg_round: 7.34,
    prev_avg_round: 7.34
  }
];
