export const HSRMocHisto = [
  {
    slug: 'acheron',
    score: 8.3,
    usage: 60.9,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 9.16,
    usage: 2.55,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 9.17,
    usage: 1.96,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 8.14,
    usage: 40.37,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 8.99,
    usage: 2.18,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 8.63,
    usage: 25.44,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 8.55,
    usage: 2.53,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 8.26,
    usage: 12.29,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 8.24,
    usage: 17.95,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 8.48,
    usage: 13.3,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.05,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 8.08,
    usage: 13.7,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 7.82,
    usage: 66.74,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 8.32,
    usage: 43.25,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 7.99,
    usage: 59.13,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 8.66,
    usage: 3.78,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 8.93,
    usage: 5.78,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 9.07,
    usage: 0.94,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 10,
    usage: 0.39,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 9.16,
    usage: 11.98,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 10.42,
    usage: 0.08,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 8.47,
    usage: 25.66,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 8.64,
    usage: 6.8,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 7.84,
    usage: 3.06,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 7.18,
    usage: 28.09,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 8.85,
    usage: 2.92,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 8.72,
    usage: 8.4,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 8.84,
    usage: 25.37,
    role: 'specialist'
  },
  {
    slug: 'luka',
    score: 9.5,
    usage: 0.82,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 8.65,
    usage: 18.03,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.54,
    usage: 2.08,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 10.48,
    usage: 0.28,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 8.52,
    usage: 9.69,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.11,
    role: 'dps'
  },
  {
    slug: 'natasha',
    score: 11,
    usage: 0.21,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 8.25,
    usage: 40.49,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 9.13,
    usage: 0.76,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 7.93,
    usage: 32.06,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 8.22,
    usage: 85.13,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 9.71,
    usage: 0.51,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 8.6,
    usage: 1.59,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 10.42,
    usage: 0.28,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 8.28,
    usage: 29.93,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.39,
    usage: 37.54,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 9.84,
    usage: 0.59,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 8.39,
    usage: 15.95,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 8,
    usage: 17.82,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 11,
    usage: 0.19,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 8.09,
    usage: 60.56,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 11,
    usage: 0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 9.35,
    usage: 1.37,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.18,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 10.02,
    usage: 0.23,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.15,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 7.34,
    usage: 18.36,
    role: 'dps'
  }
];
