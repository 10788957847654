export const MoCCurrent12_2 = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 19.76,
    rank: 1,
    avg_round: 8.14,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 4.17,
    rank: 2,
    avg_round: 7.92,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 3.54,
    rank: 3,
    avg_round: 6.72,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 3.0,
    rank: 4,
    avg_round: 6.98,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 2.24,
    rank: 5,
    avg_round: 6.27,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.99,
    rank: 6,
    avg_round: 7.98,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 1.91,
    rank: 7,
    avg_round: 7.12,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 1.75,
    rank: 8,
    avg_round: 7.11,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.64,
    rank: 9,
    avg_round: 8.73,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 1.59,
    rank: 10,
    avg_round: 8.21,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.49,
    rank: 11,
    avg_round: 6.64,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 1.47,
    rank: 12,
    avg_round: 7.08,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 1.35,
    rank: 13,
    avg_round: 8.23,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.22,
    rank: 14,
    avg_round: 8.14,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 1.08,
    rank: 15,
    avg_round: 6.68,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.95,
    rank: 16,
    avg_round: 7.29,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.81,
    rank: 17,
    avg_round: 3.03,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.76,
    rank: 18,
    avg_round: 7.8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.75,
    rank: 19,
    avg_round: 7.14,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.73,
    rank: 20,
    avg_round: 7.56,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.73,
    rank: 20,
    avg_round: 8.39,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.71,
    rank: 22,
    avg_round: 6.58,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.63,
    rank: 23,
    avg_round: 7.1,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.57,
    rank: 24,
    avg_round: 9.12,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.55,
    rank: 25,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.54,
    rank: 26,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.51,
    rank: 27,
    avg_round: 8.89,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.5,
    rank: 28,
    avg_round: 9.14,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 29,
    avg_round: 7.06,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.47,
    rank: 30,
    avg_round: 8.68,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.47,
    rank: 30,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.47,
    rank: 30,
    avg_round: 9.18,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.45,
    rank: 33,
    avg_round: 9.41,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.44,
    rank: 34,
    avg_round: 7.88,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.44,
    rank: 34,
    avg_round: 7.68,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.44,
    rank: 34,
    avg_round: 7.03,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.43,
    rank: 37,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.43,
    rank: 37,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.42,
    rank: 39,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.41,
    rank: 40,
    avg_round: 6.76,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.41,
    rank: 40,
    avg_round: 8.28,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.39,
    rank: 42,
    avg_round: 7.12,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.39,
    rank: 42,
    avg_round: 7.58,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.38,
    rank: 44,
    avg_round: 6.42,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.38,
    rank: 44,
    avg_round: 7.52,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.37,
    rank: 46,
    avg_round: 9.55,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.37,
    rank: 46,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.37,
    rank: 46,
    avg_round: 7.86,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.37,
    rank: 46,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 50,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 50,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.33,
    rank: 52,
    avg_round: 7.58,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 53,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.32,
    rank: 53,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.31,
    rank: 55,
    avg_round: 7.87,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.3,
    rank: 56,
    avg_round: 9.62,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 56,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.27,
    rank: 58,
    avg_round: 8.62,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.27,
    rank: 58,
    avg_round: 9.14,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.26,
    rank: 60,
    avg_round: 3.21,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 60,
    avg_round: 8.09,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.25,
    rank: 62,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.25,
    rank: 62,
    avg_round: 8.79,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 64,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.23,
    rank: 65,
    avg_round: 6.78,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.22,
    rank: 66,
    avg_round: 5.2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.21,
    rank: 67,
    avg_round: 7.81,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.21,
    rank: 67,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 69,
    avg_round: 8.56,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 69,
    avg_round: 7.2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 69,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 69,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.19,
    rank: 69,
    avg_round: 6.62,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 69,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 75,
    avg_round: 8.15,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 75,
    avg_round: 8.77,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 75,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 78,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 78,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 78,
    avg_round: 7.77,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.17,
    rank: 78,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 78,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.17,
    rank: 78,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 84,
    avg_round: 7.12,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 84,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 84,
    avg_round: 8.9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 8.1,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 84,
    avg_round: 9.38,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.15,
    rank: 91,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 91,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 91,
    avg_round: 7.73,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 91,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 91,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.14,
    rank: 96,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 96,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 96,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 96,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 96,
    avg_round: 8.3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 96,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 96,
    avg_round: 7.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.14,
    rank: 96,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 104,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 104,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 104,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 104,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 108,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 108,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 108,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 108,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 112,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 112,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 112,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 112,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.11,
    rank: 112,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 112,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.11,
    rank: 112,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.1,
    rank: 124,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 124,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 124,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 124,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 124,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 124,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 124,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.1,
    rank: 124,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.08,
    rank: 145,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 145,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 145,
    avg_round: 6.86,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 145,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 145,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 145,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 160,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 160,
    avg_round: 7.8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 160,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 160,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 160,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 160,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.06,
    rank: 173,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Bailu',
    app_rate: 0.06,
    rank: 173,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Bailu',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 173,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 173,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 173,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 173,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 173,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 173,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Boothill',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Luka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 217,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 217,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 217,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 217,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 217,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 251,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'march-7th',
    app_rate: 0.04,
    rank: 251,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 251,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Luka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.03,
    rank: 289,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 289,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 289,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Luka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 289,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 289,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 289,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Luocha',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Misha',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Luka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Luka',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Pela',
    app_rate: 0.02,
    rank: 369,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jingliu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Misha',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Yunli',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jingliu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Luka',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jingliu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yanqing',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 369,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Misha',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 99.99,
    star_num: '4'
  }
];
